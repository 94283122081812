<template>
  <div style="margin-top: 7%">
    <div class="col-md-4 offset-md-4">
      <div class="card shadow">
        <div class="p-5">
          <div class="media mb-3">
            <img :src="imglocal+'/imagenes/ico/contrasena.svg'"  class="mr-3" style="width: 80px;" />
            <div class="media-body  pt-4">
              <span class="h3">Iniciar sesión</span>
          
            </div>
          </div>
          Por favor ingrese los datos en el formulario para continuar
          <form @submit.prevent="enviardatos" method="POST" class="mt-3">


            <div class="form-floating mb-3">
              <input  class="form-control" v-model="email" type="number" required autocomplete="email" autofocus >
              <label for="floatingInput">Usuario:</label>
            </div>

           <div class="form-floating">
              <input type="password" class="form-control" id="password" name="password" v-model="password" required autocomplete="current-password">
              <label for="floatingPassword">Contraseña:</label>
            </div>

            <div class="mt-2 mb-2 text-danger" v-if="mensaje" > El usuario o contraseña no registran en el sistema, por favor intente nuevamente </div>
            <div class="mt-2">
              <button type="submit" class="btn btn-primary" :disabled="mostrar">
                <div class="spinner-border spinner-border-sm" role="status" v-if="mostrar">
                  <span class="visually-hidden">Loading...</span>
                </div>
                Iniciar Sesión
            </button>
            </div>
          </form>
        </div>
      </div>
          <div class="mt-3" style="opacity: 0.3;"> <strong> BAIT version 21.1.2</strong>    </div>
    </div>
  </div>
  
  </template>

<script>
import axios from "axios";
import router from '../router'

import { ref } from 'vue'
export default {
  setup() {
    
    const email = ref('') 
    const password = ref('') 
    const mensaje = ref(false) 
    const recordarme = ref(false) 

    const mostrar = ref(false)

    const enviardatos = () => {
    
    mostrar.value = true

          axios.post("/api/login", { email: email.value, password: password.value }).then(response => {         
                   
                    localStorage.setItem('token', response.data.token)
                    if(localStorage.getItem('token'))
                    {
                      // router.push('/Escritorio');
                      // router.replace('/Escritorio');
                      window.location="/Escritorio";

                    }   

                })
                .catch(function (error) {
                  mensaje.value = true
                  mostrar.value = false
          })
      }

    return { email, password, mensaje, recordarme, enviardatos, mostrar }
    
  }
   
}
</script>
 